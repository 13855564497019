import { MvGuide } from '@/lib/strapi-types/MvGuide';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

interface UseFindGuidesProps {
  filters?: Record<string, unknown>;
  populate?: string[];
}

export async function useFindGuides({ filters = {}, populate = [] }: UseFindGuidesProps = {}) {
  const defaultPopulate = ['title'];
  const allPopulate = [...defaultPopulate, ...populate];

  const parsedQuery = stringify(
    {
      populate: allPopulate,
      filters,
      pagination: { start: 0, limit: 200 }
    },
    { arrayFormat: 'brackets' }
  );

  const { data } = await useFetchWithHead<Strapi4ResponseMany<MvGuide['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.GUIDE)}?${parsedQuery}`
  );

  return { guides: data.value?.data };
}
